<app-navbar></app-navbar>
<div class="main-container" style="height: 100vh">
  <div>
    <h2>Resume</h2>
    <h1 data-text="Gabriel Madeira">Gabriel Madeira</h1>
    <svg class="main-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M64 96c0-35.3 28.7-64 64-64H512c35.3 0 64 28.7 64 64V352H512V96H128V352H64V96zM0 403.2C0 392.6 8.6 384 19.2 384H620.8c10.6 0 19.2 8.6 19.2 19.2c0 42.4-34.4 76.8-76.8 76.8H76.8C34.4 480 0 445.6 0 403.2zM281 209l-31 31 31 31c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-48-48c-9.4-9.4-9.4-24.6 0-33.9l48-48c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9zM393 175l48 48c9.4 9.4 9.4 24.6 0 33.9l-48 48c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l31-31-31-31c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0z"/></svg>
    
    </div>
</div>

<div class="container" id="about">
  <div class="row">
    <div class="col-sm-12 text-center section-title">ABOUT</div>
    <div class="col-md-3 col-sm-4 offset-md-2 offset-3 col-6 offset-sm-0">
      <img class="img-fluid img-thumbnail" src="../assets/images/profile.jpg" />
    </div>
    <div
      class="col-10 offset-1 offset-sm-0 col-md-5 col-sm-8 text-justify about-span"
    >
      <span>Hey, I'm Gabriel,</span>
      <span>a <a style="color: #2d80c4">Software Engineer 💻</a></span>
      <!-- <a
        href="../assets/files/resume_gabrielmadeira.pdf"
        target="_blank"
        class="fa fa-file-pdf-o"
      >
        <span style="font-size: 14px; display: inline"> Resume</span></a
      > -->
      <div>
      <a
        href="https://www.linkedin.com/in/gabriel-madeira"
        target="_blank"
        class="fa fa-linkedin"
      ></a>
      <a
        href="mailto:gabr.madeira@gmail.com"
        target="_blank"
        class="fa fa-envelope"
      ></a>
      <a
        href="https://github.com/gabrielmadeira"
        target="_blank"
        class="fa fa-github"
      ></a>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid container-var" id="experiences">
  <div class="col text-center section-title">EXPERIENCES</div>
  <div class="col text-center subsection-title">Work Experience</div>
  <app-card
    imgUrl="assets/images/aprix.png"
    imgLink="https://aprix.com.br/home-en/"
    cardTitle="Software Engineer"
    institution="Aprix"
    period="May 2024 - Present"
    location="Porto Alegre - Brazil"
    description="
    <b>Technology stack:</b> Python, Pandas, Angular, Docker, PostgreSQL, Microsoft Azure.
  "
  ></app-card>
  <app-card
    imgUrl="assets/images/dfki.png"
    imgLink="https://www.dfki.de/"
    cardTitle="Exchange Student"
    institution="German Research Center for Artificial Intelligence (DFKI)"
    period="April 2023 - March 2024"
    location="Kaiserslautern - Germany"
    description="
    • Designed and developed a visualization tool for the exploration of generic and large knowledge graphs through filtering, exploration, and grouping features.<br>
    • Implemented the tool module in three different knowledge graph environments: DBpedia and two private knowledge bases.<br>
    • Conducted a 10-user evaluation where the tool outperformed the baseline in one of the two defined user tasks and reached a 79.5 score in the System Usability Scale method.<br>
    <b>Technology stack:</b> Java, Spring Boot, Vue.js, D3.js, SPARQL.
  "
  ></app-card>
  <app-card
    imgUrl="assets/images/inf.png"
    imgLink="https://www.inf.ufrgs.br/site/en/"
    cardTitle="Undergraduate Researcher"
    institution="Computer Graphics and Image Processing Research Group"
    period="June 2022 - November 2022"
    location="Porto Alegre - Brazil"
    description="
    • Executed performance tests of existing trained classification/segmentation models (cytology field) fine-tuning with the group’s private oral cell image dataset.<br>
    • Studied deep learning techniques applied to image classification and segmentation.<br>
    <b>Technology stack:</b> TensorFlow, Python.
    "
  ></app-card>
  <app-card
    imgUrl="assets/images/nelogica.png"
    imgLink="https://www.nelogica.com.br/"
    cardTitle="Software Development Intern"
    institution="Nelogica"
    period="February 2021 - March 2022"
    location="Porto Alegre - Brazil"
    description="
    <b>Back-end team (October 2021 – March 2022):</b><br>
    • Implemented a database event proxy REST API service, in the OpenAPI Specification, almost zeroing the rate of accumulated database commands in the notification service.<br>
    • Created a service dynamic installer, making the process of creating specific new instances easier.<br>
    • Implemented the replaceable tags feature in the body of a message in the notification service for clients.<br>
    • <b>Technology stack:</b> Delphi.<br>

    <b>Front-end team (February 2021 – September 2021):</b><br>
    • Developed a simulated exchange wallet component for the crypto trading web platform, supporting virtual positions.<br>
    • Optimized the login screen loading with JavaScript asynchronous imports, reducing its required file size by 47%.<br>
    • Designed an asset conversion method from several different quote sources using JavaScript promises.<br>
    • <b>Technology stack:</b> Vue.js, JavaScript, CSS, HTML.
    "
  ></app-card>
  <app-card
    imgUrl="assets/images/ginfo.png"
    imgLink=""
    cardTitle="Undergraduate Researcher"
    institution="Information Management Research Group - FURG"
    period="April 2018 - July 2020"
    location="Rio Grande - Brazil"
    description="
    • Applied the Nearest Centroid algorithm and TF-IDF method to build an advisor recommendation system.<br>
    • Developed The Gold Tree, a web tool for academic genealogy analysis.<br>
    • Cleaned anomalies in a database with more than 500 thousand rows.<br>
    • Received a research scholarship.<br>
    • <b>Technology stack:</b>  PostgreSQL, JavaScript, HTML, CSS, Python, Scikit-Learn.
    <br>
    <b>Publications:</b> <br>
    <a href='https://www.researchgate.net/publication/333435352_The_Gold_Tree_An_Information_System_for_Analyzing_Academic_Genealogy' target='_blank'><b>[1]</b></a> Madeira, G., Borges, E.N., Baranano, M., Nascimento, P.K., Lucca, G., de Fatima Maia, M., Santos, H.S. and Dimuro, G.P., 2019. The Gold Tree: An Information System for Analyzing Academic Genealogy. In Proceedings of the ICEIS 2019 (pp. 114-120). <br>
    <a href='https://www.researchgate.net/publication/339384557_A_Tool_for_Analyzing_Academic_Genealogy' target='_blank'><b>[2]</b></a> Madeira, G., Borges, E.N., Lucca, G., Santos, H. and Dimuro, G., 2019. A tool for analyzing academic genealogy. In Lecture Notes in Business Information Processing, vol 378, (pp. 443–456). <br>
    <a href='https://www.scitepress.org/Papers/2021/104426/104426.pdf' target='_blank'><b>[3]</b></a> Madeira, G., Borges, E.N., Lucca, G., de Carvalho Segundo, W., Wieczynski, J.C., Santos, H.S. and Dimuro, G.P., 2021. Using Academic Genealogy for Recommending Supervisors. In Proceedings of the ICEIS 2021 (pp. 885-892).
    "
  ></app-card>
  <app-card
    imgUrl="assets/images/qwertyp.png"
    imgLink=""
    cardTitle="Computer Technician"
    institution="Qwerty News Portal"
    period="April 2016 - July 2017"
    location="Dom Pedrito - Brazil"
    description=" 
    • Developed an arrival and departure control web system for internal employees.<br>
    • Developed a web transparency portal.<br>
    • <b>Technology stack:</b> PHP, HTML, CSS, JavaScript, MySQL.
    "
  ></app-card>
  <div class="col text-center subsection-title">Education</div>
  <app-card
    imgUrl="assets/images/ufrgs.png"
    imgLink="http://www.ufrgs.br/english/home"
    cardTitle="Bachelor's degree, Computer Science"
    institution="Federal University of Rio Grande do Sul"
    period="March 2020 - August 2024"
    location="Porto Alegre - Brazil"
    description="GPA: 9.40/10.00 - Magna cum Laude<br>
    5th best Brazilian university according to the Times Higher Education’s World University Ranking 2024.
    "
  ></app-card>
  <app-card
    imgUrl="assets/images/rptu.png"
    imgLink="https://rptu.de/"
    cardTitle="Exchange Program, Computer Science"
    institution="Rheinland-Pfälzische Technische Universität Kaiserslautern-Landau"
    period="April 2023 - March 2024"
    location="Kaiserslautern - Germany"
    description="Attending Master's classes as part of an exchange program."
  ></app-card>
  <app-card
    imgUrl="assets/images/furg.png"
    imgLink="https://www.furg.br/en/"
    cardTitle="Bachelor's degree, Computer Engineering"
    institution="Federal University of Rio Grande (FURG)"
    period="March 2019 - December 2019"
    location="Rio Grande - Brazil"
    description="GPA: 9.40/10.00<br>
    (university and course transfer after one year)"
  ></app-card>
  <app-card
    imgUrl="assets/images/furg.png"
    imgLink="https://www.furg.br/en/"
    cardTitle="Bachelor's degree, Information Systems"
    institution="Federal University of Rio Grande (FURG)"
    period="March 2018 - December 2018"
    location="Rio Grande - Brazil"
    description="GPA: 9.62/10.00<br>
    (course transfer after one year)"
  ></app-card>
  <app-card
    imgUrl="assets/images/qwertye.png"
    imgLink=""
    cardTitle="Technical course, Computing Technician"
    institution="Qwerty Escola"
    period="March 2015 - December 2016"
    location="Dom Pedrito - Brazil"
    description="GPA: 9.32/10.00<br>
    Some of the topics covered:<br>

    • Logic and Programming Languages
    • Operating Systems
    • Hardware
    • Interpretation of Computer System Specifications
    • Databases
    • Programming Logic
    • Operating System
    • Types of User Interfaces
    • Designing, Analyzing, and Planning Software Development
    "
  ></app-card>
</div>

<div class="container-fluid" id="skills">
  <div class="col text-center section-title">SKILLS</div>
  <div class="col text-center">
    <app-skills></app-skills>
  </div>
</div>

<div class="container-fluid" id="projects" style="background-color: #dadada;">
  <div class="col text-center section-title">PROJECTS</div>
  <div class="col">
    <app-projects></app-projects>
  </div>
</div>