<!-- <app-navbar></app-navbar> -->
<!-- <div class="row" style="background-color: #343a40;  height: 20vh; justify-content: center;">
  <div class="mt-4" style="align-self: center;">
    <h2 style="color:aliceblue;">PROJECTS</h2>
  </div>
</div> -->
<div class="container">
  <app-project *ngFor="let project of projectList"
  githubRepositoryID="{{ project.githubRepositoryID }}"
  githubRepositoryBranch="{{ project.githubRepositoryBranch }}"
  [githubRepositoryMetadata]="project.githubRepositoryMetadata"
  [stack]="project.stack"
  ></app-project>
</div>